import logo from "./logo.svg";
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <div>
      <header className="fixed z-50 w-full bg-white py-4 sm:py-5 px-2 sm:px-3 border-b shadow-md">
        <div className="container max-w-screen-lg mx-auto flex flex-row items-center justify-between">
          <Link to="/">
            <div className="inline-flex items-center justify-center cursor-pointer">
              <img src={logo} className="h-10 w-10" alt="logo" />
            </div>
          </Link>
          <div>
            <Link
              className="inline-flex items-center justify-center text-white cursor-pointer hover:text-primary bg-primary hover:bg-transparent font-bold px-6 py-2 rounded-md border-2 border-primary"
              to="/options"
            >
              Connect
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
