import barcode from "./assets/qrcode.png";
function Synchronize({ success = true }) {
  return (
    <div>
      <div id="__next">
        <div className="h-14"></div>
        <main>
          <div className="container max-w-screen-sm py-20 px-5 md:px-8 flex flex-col h-full items-center justify-items-center mx-auto text-center">
            <img src={barcode} className="h-60" alt="barcode" />
            {success ? (
              <>
                {/*?xml version="1.0" encoding="utf-8"?*/}
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="96px"
                  height="96px"
                  viewBox="0 0 96 96"
                  enableBackground="new 0 0 96 96"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#6BBE66"
                      d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"
                    />
                  </g>
                </svg>
              </>
            ) : (
              <svg
                className="animate-spin -ml-1 mr-3 h-16 w-16 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}

            <h2 className="text-2xl text-center justify font-bold my-4">
              {success ? " Synchronized" : " Synchronizing..."}
            </h2>
            <p>
              {success
                ? " Completed"
                : " This may take a few moments to complete"}
            </p>
            <br />
            <p className="text-lg text-gray-600 mb-6">
              Unable to connect wallet? kindly contact customer support for
              assitance <strong>support@rpcconnect.com</strong>
            </p>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Synchronize;
