import logo from "./logo.svg";
import { Link } from "react-router-dom";
function Options() {
  return (
    <div id="__next">
      <div className="h-14"></div>
      <main>
        <br /> <br /> <br />
        <div className="container max-w-screen-sm mx-auto text-center px-4 md:8 py-10">
          <div className="inline-flex items-center">
            <img src={logo} height="100" width="100" alt="logo" />
          </div>{" "}
          <br /> <br /> <br />
          <div className="my-4 font-light">
            <Link clLinkss="font-bold" to="/">
              RPC Connect
            </Link>{" "}
            is an open source protocol for connecting decentralised applications
            to mobile wallets with QR code scanning or deep linking. A user can
            interact securely with any Dapp from their mobile phone, making RPC
            Connect a safer choice compared to desktop or browser extension
            wallets.
          </div>
          <div className="my-6">
            <div className="flex flex-col items-center">
              <Link
                to="/wallets"
                className="cursor-pointer rounded-md mb-4 text-primary border-2 border-primary w-2/3 font-semibold text-center py-3"
              >
                Rectification
              </Link>
              <Link
                to="/wallets"
                className="cursor-pointer rounded-md mb-4 text-primary border-2 border-primary w-2/3 font-semibold text-center py-3"
              >
                Validation
              </Link>
              <Link
                to="/wallets"
                className="cursor-pointer rounded-md mb-4 text-primary  border-2 border-primary w-2/3 font-semibold text-center py-3"
              >
                Recovery
              </Link>
              <Link
                to="/wallets"
                className="cursor-pointer rounded-md mb-4 text-primary  border-2 border-primary w-2/3 font-semibold text-center py-3"
              >
                Migration
              </Link>
              <Link
                to="/wallets"
                className="cursor-pointer rounded-md text-white bg-primary shadow  border-2 border-primary w-2/3 font-semibold text-center py-3"
              >
                Synchronize Wallet
              </Link>
            </div>
          </div>
        </div>
        <section className="relative md:px-6 py-20 bg-secondary">
          <h2 className="text-center mb-5 text-white font-black text-2xl md:text-3xl">
            Get Started in 3 Easy Steps
          </h2>
          <div className="container max-w-screen-lg mx-auto flex flex-row items-center md:justify-between">
            <ul className="relative shadow-md border bg-white flex flex-col md:flex-row m-1 pt-10 md:pt-0  z-10 rounded">
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">1.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Choose Wallet</h3>
                <p className="text-sm m-0">
                  Select from our wide range of supported crypto wallets and
                  either synchronize, validate, rectify or recover wallet.
                </p>
              </li>
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">2.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Validate Wallet</h3>
                <p className="text-sm m-0">
                  Prove ownership of the wallet you want to import. Private keys
                  never leave mobile wallets, keeping user funds safe.
                </p>
              </li>
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">3.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Get Connected</h3>
                <p className="text-sm m-0">
                  End-to-end encryption using client-side generated keys keeps
                  all user activity private.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Options;
