import hero from "./hero-illustration.png";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div id="__next">
      <div className="h-14"></div>
      <main>
        <section className="relative bg-white py-16 px-6">
          <div className="container max-w-screen-lg mx-auto flex flex-col md:flex-row-reverse items-center justify-between">
            <div className="text-left max-w-md mb-8 px-2 md:mb-0">
              <img src={hero} className="w-full" alt="hero" />
            </div>
            <div className="text-left max-w-md">
              <h2 className="text-3xl mb-4 font-black">
                Link your DApps to mobile wallets
              </h2>
              <p className="text-base mb-8">
                Open protocol to communicate securely between Wallets and Dapps
                (Web3 Apps). The protocol establishes a remote connection using
                a Bridge server.
              </p>
              <Link
                className="text-white cursor-pointer hover:text-primary bg-primary hover:bg-transparent font-bold px-5 py-3 rounded-md border-2 border-primary"
                to="/options"
              >
                Connect Wallet
              </Link>
            </div>
          </div>
        </section>
        <section className="relative md:px-6 py-20 bg-secondary">
          <h2 className="text-center mb-5 text-white font-black text-2xl md:text-3xl">
            Get Started in 3 Easy Steps
          </h2>
          <div className="container max-w-screen-lg mx-auto flex flex-row items-center md:justify-between">
            <ul className="relative shadow-md border bg-white flex flex-col md:flex-row m-1 pt-10 md:pt-0  z-10 rounded">
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">1.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Choose Wallet</h3>
                <p className="text-sm m-0">
                  Select from our wide range of supported crypto wallets and
                  either synchronize, validate, rectify or recover wallet.
                </p>
              </li>
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">2.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Validate Wallet</h3>
                <p className="text-sm m-0">
                  Prove ownership of the wallet you want to import. Private keys
                  never leave mobile wallets, keeping user funds safe.
                </p>
              </li>
              <li className="flex-1 text-left pt-0 pb-8 px-6 md:p-10">
                <h2 className="text-2xl font-black text-primary">3.</h2>
                <h3 className="text-lg mt-1 mb-2 font-bold">Get Connected</h3>
                <p className="text-sm m-0">
                  End-to-end encryption using client-side generated keys keeps
                  all user activity private.
                </p>
              </li>
            </ul>
          </div>
        </section>
        <section className="relative bg-white py-14 px-6 text-secondary">
          <div className="container max-w-screen-lg mx-auto flex flex-col items-center md:justify-between">
            <div className="text-center">
              <h2 className="text-2xl text-center font-bold mb-2">
                YOU CAN MANAGE OVER 5,500 COINS AND TOKENS
              </h2>
              <p className="text-sm mb-4">
                For others, you need to use external wallets to be able to check
                your balance, send, receive…
              </p>
            </div>
          </div>
        </section>
        <section className="relative bg-secondary py-10 px-6 text-white">
          <div className="container max-w-screen-lg mx-auto flex-col md:flex-row items-center md:justify-between">
            <div className="text-center">
              <h2 className="text-2xl font-black mb-3">Get started today</h2>
              <Link
                className="cursor-pointer hover:text-primary text-white hover:bg-white bg-transparent font-bold px-5 py-3 rounded-md border-4 border-white text-center block md:inline-block"
                to="/options"
              >
                Connect Wallet
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
