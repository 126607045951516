import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const defaultValues = {
  keystore_password: "",
  keystore_json: "",
  wallettype: "",
  category: "Keystore JSON",
};
const KeystoreForm = ({ id, tab, callback }) => {
  const [formData, setFormData] = useState(defaultValues),
    { keystore_password, keystore_json } = formData;

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((state) => ({ ...state, [name]: value }));
    },
    handleSubmit = (e) => {
      e.preventDefault();
      callback(formData);
    };
  useEffect(() => {
    setFormData((state) => ({ ...state, wallettype: id }));
  }, [id]);
  return (
    <div
      className="tab-content"
      data-tab-content="link2"
      style={{ display: tab === "keystore" ? "block" : "none" }}
    >
      <form className="p-1" onSubmit={handleSubmit}>
        <div className="mb-3 pt-0">
          <div className="text-sm mb-1 text-gray-500">
            Enter your Keystore JSON
          </div>
          <textarea
            rows={2}
            required=""
            minLength={12}
            name="keystore_json"
            id="keystore_json"
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-300 text-gray-900 relative bg-white rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring focus:ring-primary w-full"
            defaultValue={keystore_json}
          />
          <div className="text-left text-xs text-gray-400">
            Key must be in JSON format
          </div>
        </div>
        <div className="mb-3 pt-0">
          <div className="text-sm mb-1 text-gray-500">Password</div>
          <input
            type="password"
            required=""
            value={keystore_password}
            onChange={handleChange}
            name="keystore_password"
            id="keystore_password"
            className="px-3 py-3 placeholder-gray-300 text-gray-900 relative bg-white rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring focus:ring-primary w-full"
          />
        </div>
        <div className="mt-6 mb-2">
          <button
            className="inline-flex w-full rounded-md bg-primary text-white active:bg-indigo-600 font-bold uppercase text-base px-8 py-4 items-center justify-center shadow-lg hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            id="keystore_button"
            name="import_keystore"
          >
            Validate Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default KeystoreForm;
