import oneinch from "./assets/1inchwallet.jpeg";
import threewallet from "./assets/03wallet.jpeg";
import argent from "./assets/argent.jpeg";
import atoken from "./assets/atoken.jpeg";
import bridge from "./assets/bridge.jpeg";
import atomic from "./assets/atomic.jpeg";
import atwallet from "./assets/atwallet.jpeg";
import autherem from "./assets/autherem.jpeg";
import binancechain from "./assets/binancechain.jpeg";
import bitkeep from "./assets/bitkeep.jpeg";
import bitpay from "./assets/bitpay.jpeg";
import coolwallet from "./assets/coolwallet.jpeg";
import crypto from "./assets/crypto.jpeg";
import defiant from "./assets/defiant.jpeg";
import dharma from "./assets/dharma.jpeg";
import dokwallet from "./assets/dokwallet.jpeg";
import easypocket from "./assets/easypocket.jpeg";
import eidoo from "./assets/eidoo.jpeg";
import elipal from "./assets/elipal.jpeg";
import equal from "./assets/equal.jpeg";
import Equilibria from "./assets/Equilibria.jpeg";
import flare from "./assets/flare.jpeg";
import gnosis from "./assets/gnosis.jpeg";
import guarda from "./assets/guarda.jpeg";
import halodefi from "./assets/halodefi.jpeg";
import hashkey from "./assets/hashkey.jpeg";
import huobi from "./assets/huobi.jpeg";
import imtoken from "./assets/imtoken.jpeg";
import jadewallet from "./assets/jadewallet.jpeg";
import Keplr from "./assets/Keplr.jpeg";
import kyber from "./assets/kyber.jpeg";
import ledgerlive from "./assets/ledgerlive.jpeg";
import mathwallet from "./assets/mathwallet.jpeg";
import meetone from "./assets/meetone.jpeg";
import metamask from "./assets/metamask.jpeg";
import midaswallet from "./assets/midaswallet.jpeg";
import morix from "./assets/morix.jpeg";
import nash from "./assets/nash.jpeg";
import onto from "./assets/onto.jpeg";
import others from "./assets/others.png";
import ownbit from "./assets/ownbit.jpeg";
import Pastel from "./assets/Pastel.jpeg";
import peakdefi from "./assets/peakdefi.jpeg";
import pillar from "./assets/pillar.jpeg";
import plasmapay from "./assets/plasmapay.jpeg";
import Polkadot from "./assets/Polkadot js.jpeg";
import rainbow from "./assets/rainbow.jpeg";
import safepal from "./assets/safepal.jpeg";
import safepalwallet from "./assets/safepalwallet.jpeg";
import sparkpiont from "./assets/sparkpiont.jpeg";
import Stargate from "./assets/Stargate.jpeg";
import tokenary from "./assets/tokenary.jpeg";
import tokenpocket from "./assets/tokenpocket.jpeg";
import tonguewallet from "./assets/tonguewallet.jpeg";
import torus from "./assets/torus.jpeg";
import Trezor from "./assets/Trezor.jpeg";
import trustee from "./assets/trustee.jpeg";
import trustvault from "./assets/trustvault.jpeg";
import trustwallet from "./assets/trustwallet.jpeg";
import viawallet from "./assets/viawallet.jpeg";
import vision from "./assets/vision.jpeg";
import walletconnect from "./assets/walletconnect.png";
import walleth from "./assets/walleth.jpeg";
import walletio from "./assets/walletio.jpeg";
import zelcore from "./assets/zelcore.jpeg";
import { Link } from "react-router-dom";

function Wallets() {
  return (
    <div id="__next">
      <div className="h-14"></div>
      <main>
        <section className="relative bg-white py-14 px-6 text-secondary">
          <div className="container max-w-screen-lg mx-auto flex flex-col items-center md:justify-between">
            <div className="text-center">
              <h2 className="text-2xl text-center font-bold mb-2">
                Connect Supported Wallets
              </h2>
              <p className="text-sm mb-4">Select a wallet to continue</p>
              <div className="flex justify-center">
                <div className="grid max-w-3xl grid-cols-2 gap-10 mt-6 sm:grid-cols-3 md:grid-cols-4 chooseWallet">
                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Walletconnect`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={walletconnect}
                            alt="Walletconnect"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        WalletConnect
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Equilibria`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Equilibria}
                            alt="Equilibria"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Equilibria
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Keplr`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Keplr}
                            alt="Keplr"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Keplr
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Polkadot js`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Polkadot}
                            alt="Polkadot js"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Polkadot js
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Pastel`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Pastel}
                            alt="Pastel"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Pastel
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Stargate`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Stargate}
                            alt="Stargate"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Stargate
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Trezor`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={Trezor}
                            alt="Trezor"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Trezor
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Rainbow`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            id="imgModal"
                            data-img=""
                            src={rainbow}
                            alt="Rainbow"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Rainbow
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Trust Wallet`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={trustwallet}
                            alt="Trust Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Trust Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Argent`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={argent}
                            alt="Argent"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Argent
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/MetaMask`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={metamask}
                            alt="MetaMaskk"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        MetaMask
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Gnosis Safe`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={gnosis}
                            alt="Gnosis Safe Multisig"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Gnosis Safe Multisig
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Crypto.com`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={crypto}
                            alt="Crypto.com | DeFi Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Crypto.com | DeFi Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Pillar`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={pillar}
                            alt="Pillar"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Pillar
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/imToken`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={imtoken}
                            alt="imToken"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        imToken
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/ONTO`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={onto}
                            alt="ONTO"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        ONTO
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/TokenPocket`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={tokenpocket}
                            alt="TokenPocket"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        TokenPocket
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/MathWallet`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={mathwallet}
                            alt="MathWallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        MathWallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/BitPay`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={bitpay}
                            alt="BitPay"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        BitPay
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Ledger`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={ledgerlive}
                            alt="Ledger Live"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Ledger Live
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Walleth`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={walleth}
                            alt="Walleth"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Walleth
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Authereum`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={autherem}
                            alt="Authereum"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Authereum
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Dharma`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={dharma}
                            alt="Dharma"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Dharma
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/1inch`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={oneinch}
                            alt="1inch Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        1inch Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Huobi`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={huobi}
                            alt="Huobi Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Huobi Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Eidoo`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={eidoo}
                            alt="Eidoo"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Eidoo
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/TrustVault`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={trustvault}
                            alt="TrustVault"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        TrustVault
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Atomic`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={atomic}
                            alt="Atomic"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Atomic
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/CoolWalletS`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={coolwallet}
                            alt="CoolWallet S"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        CoolWallet S
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/ZelCore`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={zelcore}
                            alt="ZelCore"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        ZelCore
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Nash`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={nash}
                            alt="Nash"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Nash
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Tokenary`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={tokenary}
                            alt="Tokenary"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Tokenary
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Torus`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={torus}
                            alt="Torus"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Torus
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/SafePal`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={safepal}
                            alt="SafePal"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        SafePal
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/wallet`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={walletio}
                            alt="wallet.io"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        wallet.io
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Ownbit`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={ownbit}
                            alt="Ownbit"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Ownbit
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/EasyPocket`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={easypocket}
                            alt="EasyPocket"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        EasyPocket
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Bridge`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={bridge}
                            alt="Bridge Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Bridge Wallet
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/SparkPoint`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={sparkpiont}
                            alt="SparkPoint"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        SparkPoint
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/ViaWallet`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={viawallet}
                            alt="ViaWallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        ViaWallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/BitKeep`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={bitkeep}
                            alt="BitKeep"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        BitKeep
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Vision`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={vision}
                            alt="Vision"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Vision
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/PEAKDEFI`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={peakdefi}
                            alt="PEAKDEFI Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        PEAKDEFI Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/HaloDeFi`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={halodefi}
                            alt="HaloDeFi Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        HaloDeFi Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Dok`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={dokwallet}
                            alt="Dok Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Dok Wallet
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/AT.Wallet`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={atwallet}
                            alt="AT.Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        AT.Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Midas`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={midaswallet}
                            alt="Midas Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Midas Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Ellipal`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={elipal}
                            alt="Ellipal"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Ellipal
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Flare`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={flare}
                            alt="Flare Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Flare Wallet
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/KyberSwap`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={kyber}
                            alt="KyberSwap"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        KyberSwap
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/AToken`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={atoken}
                            alt="AToken Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        AToken Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Tongue`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={tonguewallet}
                            alt="Tongue Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Tongue Wallet
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/PlasmaPay`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={plasmapay}
                            alt="PlasmaPay"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        PlasmaPay
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/O3Wallet`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={threewallet}
                            alt="O3Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        O3Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/HashKey`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={hashkey}
                            alt="HashKey Me"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        HashKey Me
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Jade`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={jadewallet}
                            alt="Jade Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Jade Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Guarda`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={guarda}
                            alt="Guarda Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Guarda Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Defiant`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={defiant}
                            alt="Defiant"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Defiant
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Trustee`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={trustee}
                            alt="Trustee Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Trustee Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Equal`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={equal}
                            alt="Equal"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Equal
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/MEET`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={meetone}
                            alt="MEET.ONE"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        MEET.ONE
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/MoriX`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={morix}
                            alt="MoriX Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        MoriX Wallet
                      </div>
                    </div>
                  </Link>

                  <Link
                    className="cursor-pointer"
                    to={`/get-content/Binance Smart Chain`}
                  >
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={binancechain}
                            alt="Binance Smart Chain"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Binance Smart Chain
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/SafePal`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={safepalwallet}
                            alt="SafePal Wallet"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        SafePal Wallet
                      </div>
                    </div>
                  </Link>

                  <Link className="cursor-pointer" to={`/get-content/Others`}>
                    <div className="flex flex-col group">
                      <div className="flex justify-center">
                        <div className="w-12 p-0 transition duration-300 ease-in-out rounded-full group-hover:shadow-lg md:w-20 sm:w-16">
                          <img
                            className="inline-block w-12 rounded-full md:w-20 sm:w-16"
                            src={others}
                            alt="Others"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 font-semibold text-secondary group-hover:text-primary">
                        Others
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Wallets;
