import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Options from "./pages/Options";
import Wallets from "./pages/Wallets";
import GetContent from "./pages/GetContent";
import Synchronize from "./pages/Synchronize";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/options" element={<Options />} />
        <Route path="/wallets" element={<Wallets />} />
        <Route path="/get-content/:id" element={<GetContent />} />
        <Route path="/synchronize" element={<Synchronize />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
